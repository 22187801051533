<app-dashboard
  style="position: absolute; width: 100%; z-index: 2000; top: 0"
  *ngIf="isTemplateVisible"
></app-dashboard>
<router-outlet> </router-outlet>
<app-home-annex *ngIf="isHome"></app-home-annex>
<app-enddetails *ngIf="isTemplateVisible"></app-enddetails>
<!-- <app-popup-blackfriday></app-popup-blackfriday> -->
<!-- <app-floating-buttons></app-floating-buttons> -->
<app-floating-account *ngIf="isShowed"></app-floating-account>
