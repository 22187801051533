import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';
// import { AuthenticationService } from 'src/app/service/authentication.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  // constructor(private authService: AuthenticationService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // const token = this.authService.getToken();
    // if (token) {
    //   request = request.clone({
    //     setHeaders: {
    //       Authorization: `Bearer ${token}`,
    //     },
    //   });
    // }
    // request = request.clone({
    //   headers: request.headers.delete('baggage'), // Supprimez explicitement le header
    // });
    return next.handle(request);
  }
}
