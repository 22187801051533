<nav class="navbar navbar-expand-lg" id="navbar-menu" #navbar>
  <div class="container-fluid mx-0 px-0">
    <!-- Logo logo-wrapper-->
    <div class="">
      <a class="logo" routerLink="/" routerLinkActive="active">
        <img
          src="assets/images/lhd/LUXURY white.png"
          class="logo-img"
          alt="logo"
          width="136px"
          height="81px"
          loading="lazy"
        />
      </a>
    </div>
    <!-- Button -->
    <button
      (click)="navBarShown = !navBarShown"
      class="navbar-toggler"
      type="button"
      data-bs-toggle="collapse"
      data-bs-target="#navbar"
      aria-controls="navbar"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <fa-icon [icon]="faBars"></fa-icon>
    </button>
    <!-- Menu -->
    <div
      class="collapse navbar-collapse"
      id="navbar"
      [class.show]="navBarShown"
    >
      <ul class="navbar-nav ms-auto">
        <li class="nav-item">
          <a
            class="nav-link"
            aria-current="page"
            routerLink="/"
            routerLinkActive="active"
            (click)="navBarShown = !navBarShown"
            >HOME</a
          >
          <div class="underline"></div>
        </li>
        <li class="nav-item">
          <a
            class="nav-link"
            aria-current="page"
            routerLink="/about-us"
            routerLinkActive="active"
            id="needwid"
            style="text-wrap: nowrap"
            (click)="navBarShown = !navBarShown"
            >ABOUT US</a
          >

          <div class="underline"></div>
        </li>
        <li class="nav-item dropdown drop_1">
          <a
            class="nav-link dropdown-toggle"
            [class.show]="destinationMenuShown"
            role="button"
            data-bs-toggle="dropdown"
            data-bs-auto-close="outside"
            aria-expanded="false"
            (click)="destinationMenuShown = !destinationMenuShown"
            >DESTINATIONS <span class="icon icon1">&raquo;</span></a
          >
          <ul
            class="dropdown-menu dropdown-menu1"
            [class.show]="destinationMenuShown"
            [class.hide]="!destinationMenuShown"
            id="navbar2"
          >
            <li
              class="dropdown-submenu dropdown"
              *ngFor="let continent of continents | keyvalue"
              style="font-size: 1rem !important"
            >
              <a
                (click)="
                  activeSubMenu === continent.key
                    ? (activeSubMenu = undefined)
                    : (activeSubMenu = continent.key)
                "
                id="africa-nav-dropdown"
                class="dropdown-item dropdown-toggle"
                [class.show]="activeSubMenu === continent.key"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                tabindex="0"
                >{{ continent.key }} <span class="icon">&raquo;</span>
              </a>
              <ul
                class="dropdown-menu dropdown-menu2"
                [class.show]="activeSubMenu === continent.key"
              >
                <li>
                  <a
                    [routerLink]="[
                      '/destinations',
                      'luxury-hotels-in-' +
                        destination.destination
                          .toLowerCase()
                          .split(' ')
                          .join('-'),
                      destination.destinationId
                    ]"
                    data-rr-ui-dropdown-item=""
                    class="dropdown-item"
                    *ngFor="let destination of continent.value"
                    id="{{ destination.destinationId }}"
                    onclick="hideDropdownToggle()"
                    >{{ destination.destination }}</a
                  >
                </li>
              </ul>
            </li>
          </ul>
        </li>
        <li class="nav-item">
          <a
            class="nav-link"
            routerLink="/offers-deals"
            routerLinkActive="active"
            (click)="navBarShown = !navBarShown"
            >OFFERS&DEALS</a
          >
          <div class="underline"></div>
        </li>
        <li class="nav-item">
          <a
            class="nav-link"
            style="text-wrap: nowrap"
            routerLink="/weddings-honeymoons"
            routerLinkActive="active"
            id="honey"
            (click)="navBarShown = !navBarShown"
            >HONEYMOON INSPIRATION</a
          >
          <div class="underline"></div>
        </li>
        <li class="nav-item">
          <a
            class="nav-link"
            routerLink="/tours"
            routerLinkActive="active"
            (click)="navBarShown = !navBarShown"
            >TOURS</a
          >
          <div class="underline"></div>
        </li>
        <li class="nav-item">
          <a
            class="nav-link"
            routerLink="/request-quote"
            routerLinkActive="active"
            (click)="navBarShown = !navBarShown"
            >REQUEST A QUOTE</a
          >
          <div class="underline"></div>
        </li>
        <li class="nav-item">
          <a
            class="nav-link"
            routerLink="/blog"
            routerLinkActive="active"
            (click)="navBarShown = !navBarShown"
            >BLOG</a
          >
          <div class="underline"></div>
        </li>
      </ul>
    </div>
  </div>
</nav>
<div class="repere" #stickyTrigger></div>
