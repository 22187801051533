import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Router, NavigationEnd } from '@angular/router';
import { ApiService } from 'src/app/service/api.service';
import { LocalStorageCacheService } from 'src/app/service/local-storage-cache.service';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { Subscription } from 'rxjs';
import { AfterViewInit } from '@angular/core';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
})
export class DashboardComponent implements OnInit, AfterViewInit, OnDestroy {
  continents = new Map<String, any>();
  navBarShown = false;
  destinationMenuShown = false;
  activeSubMenu;
  faBars = faBars;
  subscriptions: Subscription[] = [];
  @ViewChild('navbar') navbarElement!: ElementRef;
  @ViewChild('stickyTrigger') stickyTriggerElement!: ElementRef;
  private observer!: IntersectionObserver;
  constructor(
    private router: Router,
    @Inject(PLATFORM_ID) private platformId: any,
    @Inject(DOCUMENT) private _doc: Document,
    private api: ApiService,
    private cache: LocalStorageCacheService,
    private renderer: Renderer2
  ) {
    /*this._doc.addEventListener(
      'scroll',
      function () {
        const navBar = this.getElementById('navbar-menu');
        if (navBar) {
          if (this.defaultView.window.scrollY > 300) {
            navBar.classList.remove('fadeIn');
            this.getElementsByTagName('body')[0].classList.add('shrink');
            navBar.classList.add(
              'fixed-top',
              'animated',
              'fadeInDown',
              'black-backgound'
            );
          } else {
            navBar.classList.remove(
              'fadeInDown',
              'fixed-top',
              'black-backgound'
            );
            this.getElementsByTagName('body')[0].classList.remove('shrink');
            navBar.classList.add('animated', 'fadeIn');
          }
        }
      },
      { passive: true }
    );*/
  }
  ngAfterViewInit(): void {
    this.createObserver();
  }
  ngOnInit(): void {
    const cachedData = this.cache.getItem('nav');

    if (cachedData) {
      this.continents = cachedData;
    } else {
      const subscription = this.api.getContinents().subscribe((val) => {
        this.continents = val;

        this.cache.setItem('nav', val);
      });
      this.subscriptions.push(subscription);
    }
    if (isPlatformBrowser(this.platformId)) {
      this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          this._doc.defaultView.scrollTo(0, 0);
        }
      });
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    if (this.observer) {
      this.observer.disconnect();
    }
  }
  private createObserver() {
    const options = {
      root: null,
      threshold: 0,
    };
    if (typeof window !== 'undefined' && 'IntersectionObserver' in window) {
      this.observer = new IntersectionObserver(([entry]) => {
        if (entry.isIntersecting) {
          this.renderer.removeClass(this.navbarElement.nativeElement, 'sticky');
        } else {
          this.renderer.addClass(this.navbarElement.nativeElement, 'sticky');
        }
      }, options);

      this.observer.observe(this.stickyTriggerElement.nativeElement);
    }
  }
}
