<div id="container-floating">
  <ng-container *ngIf="!isAuthenticate; else logout">
    <div class="nd3 nds" data-bs-toggle="modal" data-bs-target="#exampleModal">
      <img
        class="reminder"
        src="assets/icons/login.png"
        style="display: inline; height: 20px; margin-bottom: 0.5rem"
        alt="log in"
      />
      <!-- <span class="infobulle" aria-label="texte de l'infobulle">login</span> -->
    </div>
    <div class="nd4 nds" data-bs-toggle="modal" data-bs-target="#signinModal">
      <img
        class="reminder"
        src="assets/icons/register.png"
        style="display: inline; height: 20px"
        alt="Signin"
      />
    </div>
  </ng-container>
  <ng-template #logout>
    <div
      class="nd3 nds"
      data-bs-toggle="modal"
      data-bs-target="#confirmModal"
      style="background-color: red"
    >
      <img
        class="reminder"
        src="assets/icons/log-out.png"
        style="display: inline; height: 20px"
        alt="Logout"
      />
    </div>
  </ng-template>

  <div id="floating-button">
    <p class="plus">
      <img
        src="assets/icons/user-white.png"
        alt="user-icon"
        style="display: inline; height: 30px; margin-bottom: 0.2rem"
      />
    </p>
    <img
      class="edit"
      src="https://ssl.gstatic.com/bt/C3341AA7A1A076756462EE2E5CD71C11/1x/bt_compose2_1x.png"
      alt="Edit"
    />
  </div>
</div>
<!-- Signin -->
<div
  class="modal fade"
  id="signinModal"
  tabindex="-1"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
  style="z-index: 4000"
>
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h3 class="modal-title" id="exampleModalLabel">Signin</h3>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
          #closeSignin
        ></button>
      </div>
      <form [formGroup]="userSigninForm">
        <div class="modal-body">
          <div class="alert alert-danger" *ngIf="resultSignin">
            <p><b>Signin error</b></p>
            <small style="font-size: smaller"> {{ resultSignin }}</small>
          </div>
          <div class="form-group mb-3">
            <label for="username" class="col-sm-2 col-form-label"
              >Username</label
            >
            <input
              type="text"
              class="form-control"
              id="username"
              placeholder="Username"
              formControlName="username"
              autocomplete="username"
            />
            <div
              *ngIf="
                userSigninForm.get('username').invalid &&
                userSigninForm.get('username').touched
              "
              class="text-danger"
            >
              <small *ngIf="username?.errors?.['required']">
                Username is required</small
              >
              <small *ngIf="username?.errors?.['minlength']"
                >Username must be at least 8 characters long.</small
              >
            </div>
          </div>
          <div class="form-group mb-3">
            <label for="email" class="col-sm-2 col-form-label">Email</label>
            <input
              type="email"
              class="form-control"
              id="email"
              placeholder="Your email"
              formControlName="email"
              autocomplete="username"
            />
            <div
              *ngIf="email?.invalid && email?.touched"
              class="text-danger"
              style="font-size: smaller"
            >
              <small *ngIf="email?.errors?.['required']">
                Email is required</small
              >
              <small *ngIf="email?.errors?.['email']">
                Email is not valid</small
              >
            </div>
          </div>
          <div class="form-group mb-3">
            <label for="password" class="form-label">Password</label>
            <input
              type="password"
              class="form-control"
              id="password"
              formControlName="password"
              autocomplete="new-password"
            />
            <div
              *ngIf="password?.invalid && password?.touched"
              class="text-danger"
              style="font-size: smaller"
            >
              <small *ngIf="password?.errors?.['minlength']"
                >Password must be at least 8 characters long.</small
              >
              <small *ngIf="password?.errors?.['required']"
                >Password is required.</small
              >
              <small *ngIf="password?.errors?.['weakPassword']"
                >Password is too weak.</small
              >
            </div>
          </div>
          <div class="form-group mb-3">
            <label for="password" class="form-label">Confirm password</label>
            <input
              type="password"
              class="form-control"
              id="confirmPassword"
              formControlName="confirmPassword"
              autocomplete="new-password"
            />
            <div
              *ngIf="userSigninForm.errors?.['mismatch'] && confirmPassword.touched"
              style="font-size: smaller"
              class="text-danger"
            >
              <small>Passwords do not match.</small>
            </div>
          </div>
          <!-- <div class="form-group">
            <input
              type="file"
              class="form-control-file"
              id="exampleFormControlFile1"
              (change)="handleImage($event)"
            />
          </div> -->
          <div class="d-flex mt-3">
            <input
              type="checkbox"
              style="display: block; height: 20px; margin-right: 10px"
              formControlName="subscribtion"
            /><label
              for=""
              style="text-align: left"
              class="text-subscribe"
              (click)="check()"
            >
              Subscribe to our newsletter for the latest news and offers</label
            >
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="submit"
            class="btn btn-primary"
            [disabled]="userSigninForm.invalid"
            (click)="handleSignin()"
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
<!-- login -->
<div
  class="modal fade"
  id="exampleModal"
  tabindex="-1"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
  style="z-index: 4000"
  #exampleModal
>
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h3 class="modal-title" id="exampleModalLabel">Login</h3>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
          #closebutton
        ></button>
      </div>
      <form [formGroup]="userForm">
        <div class="modal-body">
          <div class="alert alert-danger" *ngIf="this.loginError">
            <p><b>Wrong Credentials</b></p>
            <small style="font-size: smaller"> {{ this.loginError }}</small>
          </div>
          <div class="form-group mb-3">
            <label for="email" class="col-sm-2 col-form-label">Email</label>
            <input
              type="email"
              class="form-control"
              id="lmail"
              placeholder="Your email"
              formControlName="lemail"
              autocomplete="username"
            />
            <div
              *ngIf="lemail?.invalid && lemail?.touched"
              class="text-danger"
              style="font-size: smaller"
            >
              <small *ngIf="lemail?.errors?.['required']">
                Email is required</small
              >
              <small *ngIf="lemail?.errors?.['email']">
                Email is not valid</small
              >
            </div>
          </div>
          <div class="form-group mb-3">
            <label for="password" class="form-label">Password</label>
            <input
              type="password"
              class="form-control"
              id="lpassword"
              formControlName="lpassword"
              autocomplete="new-password"
            />
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="submit"
            class="btn btn-primary"
            [disabled]="userForm.invalid"
            (click)="handleLogin()"
          >
            Login
          </button>
        </div>
      </form>
    </div>
  </div>
</div>

<!-- Modal -->
<div
  class="modal fade"
  id="confirmModal"
  tabindex="-1"
  aria-labelledby="confirmModalLabel"
  aria-hidden="true"
  style="z-index: 25000000"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h3 class="modal-title" id="confirmModalLabel">Logout ?</h3>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body">
        <p>Are you sure you want to log out?</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
          Annuler
        </button>
        <button
          type="button"
          class="btn btn-primary"
          id="confirmAction"
          (click)="handleLogout()"
          data-bs-dismiss="modal"
        >
          Confirmer
        </button>
      </div>
    </div>
  </div>
</div>
<div class="overlay hidden" #overlay></div>
<div class="uploader-container hidden-u" #uploadImg>
  <img src="" alt="Default" class="profile-img" #imgProfile />
  <input
    type="file"
    class="btn-choose"
    placeholder=""
    (change)="handleImage($event)"
    accept="image/*"
  />

  <span
    class="text-danger"
    style="
      position: absolute;
      z-index: 556554422254;
      top: 60%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 12px;
    "
    *ngIf="!!setErrorImg || !!errUpload"
  >
    {{ setErrorImg ? setErrorImg : errUpload }}
  </span>
  <button class="terminer" [disabled]="!isImageSet" (click)="setUserImg()">
    Finish
  </button>
</div>
<!-- <button (click)="showModal()"></button> -->
