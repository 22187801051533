import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import {
  AbstractControl,
  AsyncValidatorFn,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { Meta } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { error } from 'console';
import { BehaviorSubject, catchError, map, Observable, of } from 'rxjs';
import { AuthenticationService } from 'src/app/service/authentication.service';
// import { AuthenticationService } from 'src/app/service/authentication.service';
// import { AuthService } from 'src/app/service/auth.service-old';
import { StrapiService } from 'src/app/service/strapi.service';
import { TitleService } from 'src/app/service/title.service';
import { environment } from 'src/environments/environment';
declare var bootstrap: any;
export function passwordStrengthValidator(): AsyncValidatorFn {
  return (control: AbstractControl): Observable<ValidationErrors | null> => {
    const password = control.value;

    if (!password) {
      return of(null); // Pas de validation si le champ est vide
    }

    // Exemple de validation (vous pouvez remplacer cela par une logique réelle)
    const isWeak = password.length < 8; // Vérifiez si le mot de passe est trop court

    return of(isWeak).pipe(
      map((weak) => (weak ? { weakPassword: true } : null)),
      catchError(() => of(null)) // Gestion des erreurs
    );
  };
}
@Component({
  selector: 'app-floating-account',
  templateUrl: './floating-account.component.html',
  styleUrls: ['./floating-account.component.css'],
})
export class FloatingAccountComponent implements OnInit {
  errUpload: any;
  errSub: any;
  setErrorImg: any;
  showModal() {
    this.overlay.nativeElement.classList.remove('hidden');
    this.uploadImg.nativeElement.classList.remove('hidden-u');
  }
  /* backendUrl = environment.STRAPI_URL;
  // documentId: string = '';
  // article: any;
  // isLoading: boolean = true;*/
  userForm: FormGroup;
  /*
  token: string | undefined;
  // currentPage: number = 0;
  // commentForm = new FormGroup({
  //   titre: new FormControl(''),
  //   content: new FormControl(''),
  // });
  currentUser: any;*/
  userSigninForm: FormGroup;
  /*
  // private _data = ;*/
  isAuthenticate: boolean = false;
  /*
  // loadingComments: boolean = false;
  // comments: any[];
  // commentError: string;*/
  file: any | null = null;

  loginError: string = '';
  isImageSet: boolean = false;
  resultSignin: any;
  /*
  // popularArticles: any;
  // categories: any;*/
  constructor(
    private strapiService: StrapiService,
    private formBuilder: FormBuilder,
    private http: HttpClient,
    private authService: AuthenticationService,
    private crf: ChangeDetectorRef
  ) {
    // this.token = undefined;
    // this.authService.isAuthenticated$.subscribe(
    //   (isAuthenticate) => (this.isAuthenticate = isAuthenticate)
    // );
    this.userSigninForm = this.formBuilder.group(
      {
        email: ['', [Validators.required, Validators.email]],
        username: ['', [Validators.required, Validators.minLength(4)]],
        password: [
          '',
          [Validators.required, Validators.minLength(8)],
          [passwordStrengthValidator()],
        ],
        confirmPassword: ['', Validators.required],
        subscribtion: [true],
      },
      { validator: this.passwordMatchValidator }
    );
    this.userForm = this.formBuilder.group({
      lemail: ['', [Validators.required, Validators.email]],
      lpassword: [
        '',
        [Validators.required, Validators.minLength(8)],
        [passwordStrengthValidator()],
      ],
    });
  }
  ngOnInit(): void {
    //localStorage.getItem('isAuthenticate')
    //   ? localStorage.getItem('isAuthenticate') != ''
    //   : false;*
    this.authService.user$.subscribe({
      next: (data) => {
        this.isAuthenticate = data;
      },
    });
  }
  @ViewChild('closebutton') closebutton;
  @ViewChild('closeSignin') closeSignin;
  @ViewChild('overlay') overlay;
  @ViewChild('uploadImg') uploadImg;
  @ViewChild('imgProfile') imgProfile;
  passwordMatchValidator(form: FormGroup) {
    return form.get('password')?.value === form.get('confirmPassword')?.value
      ? null
      : { mismatch: true };
  }

  handleLogin() {
    // console.log(this.userForm.get('lpassword').value);
    this.authService
      .login(
        this.userForm.get('lemail').value,
        this.userForm.get('lpassword').value
      )
      .subscribe({
        next: (data) => {
          // console.log(data);
          this.userForm.reset();
          this.closebutton.nativeElement.click();
        },
        error: (err) => {
          // console.log(err);
          this.loginError = err.error.error.message;
        },
      });
  }

  //  onClick() {
  //   grecaptcha.ready(function() {
  //     grecaptcha.execute('reCAPTCHA_site_key', {action: 'submit'}).then(function(token) {
  //         // Add your logic to submit to your backend server here.
  //     });
  //   });
  // }
  handleSignin() {
    const formData = new FormData();

    this.authService
      .register(
        this.userSigninForm.get('email').value,
        this.userSigninForm.get('password').value,
        this.userSigninForm.get('username').value
      )
      .subscribe({
        next: (subRes) => {
          // console.log(subRes);
          this.closeSignin.nativeElement.click();
          //affichage default user image
          this.imgProfile.nativeElement.setAttribute(
            'src',
            environment.STRAPI_URL + subRes.user.imglink
          );
          this.userSigninForm.reset();
          this.showModal();
        },
        error: (errSub) => {
          // console.log(errSub);
          this.errSub = errSub.error.error.message;
        },
      });

    formData.append('img', this.file);
  }
  ///****getting file */
  handleImage(event) {
    //  / const input = event.target;
    //   if (input.files[0]) {
    //     this.file = input.files[0] as File;
    //     this.isImageSet = true;
    //   }

    this.file = event.target.files[0]; // Récupère le premier fichier sélectionné

    if (this.file && this.file.type.startsWith('image/')) {
      const reader = new FileReader();

      // Définit la fonction de callback lorsque la lecture est terminée
      reader.onload = (e) => {
        this.imgProfile.nativeElement.setAttribute('src', e.target.result); // Définit l'image comme source
      };
      this.isImageSet = true;
      reader.readAsDataURL(this.file); // Lit le fichier comme une URL de données (base64)
    } else {
      alert('Veuillez sélectionner un fichier image valide.');
    }
  }
  check() {
    this.userSigninForm.patchValue({
      subscribtion: !this.userSigninForm.get('subscribtion')?.value,
    });
  }
  setUserImg() {
    //saving image profile
    if (this.file) {
      let user;
      // console.log(localStorage.getItem('user'));
      if (localStorage.getItem('user')) {
        user = JSON.parse(localStorage.getItem('user'));
        // console.log(user);
      }
      this.strapiService.uploadImage(this.file).subscribe({
        next: (imageRes) => {
          // console.log(imageRes);
          // setting user image
          this.authService
            .setUserImage(
              imageRes[0].documentId,
              imageRes[0].formats.small.url,
              user.id
            )
            .subscribe({
              next: (userWithImg) => {
                //updating user in local storage
                localStorage.setItem('user', JSON.stringify(userWithImg));

                this.uploadImg.nativeElement.classList.add('hidden');
                this.overlay.nativeElement.classList.add('hidden');
              },
              error: (errSetImg) => {
                this.setErrorImg = errSetImg.error.error.message;
              },
            });
        },
        error: (errUpload) => {
          this.errUpload = errUpload.error.error.message;
        },
      });
    }
  }
  handleLogout() {
    this.authService.logout();
  }
  /*******getter  */
  get password() {
    return this.userSigninForm.get('password');
  }
  get email() {
    return this.userSigninForm.get('email');
  }
  get lpassword() {
    return this.userForm.get('lpassword');
  }
  get lemail() {
    return this.userForm.get('lemail');
  }
  get confirmPassword() {
    return this.userSigninForm.get('confirmPassword');
  }
  get subscribtion() {
    return this.userSigninForm.get('subscribtion');
  }
  get username() {
    return this.userSigninForm.get('username');
  }
  subscribleToNewsletter() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    if (this.userSigninForm.value.subscribtion) {
      this.http
        .post(
          `https://email.luxuryholidaysdirect.com/contact/add?version=2&publicaccountid=7ed18b4e-56d5-423c-878e-f8a4a07cbc53&email=${this.userSigninForm.value.email}`,
          {
            email: this.userSigninForm.value.email,
            field_firstname: this.userSigninForm.value.username,
            field_lastname: this.userSigninForm.value.field_lastname,
            publiclistid: 'cd405fda-994e-40f5-9aa8-c946073594c9',
            submit: 'Subscribe Now',
            publicaccountid: '7ed18b4e-56d5-423c-878e-f8a4a07cbc53',
            returnUrl: '',
            activationReturnUrl: '',
            alreadyactiveurl: '',
            activationTemplate: '',
            source: 'WebForm',
            verifyemail: 'false',
            captcha: 'true',
            sendActivation: 'true',
            notifyEmail: '',
          },
          httpOptions
        )
        .subscribe((res) => {
          //  console.log(res),
          (err) => {
            //     console.log(err);
          };
        });
    }
  }
}
