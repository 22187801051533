import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { error } from 'node:console';
// import { State } from '@popperjs/core';
import {
  BehaviorSubject,
  Observable,
  catchError,
  map,
  of,
  tap,
  throwError,
} from 'rxjs';
import { environment } from 'src/environments/environment';
import { Article } from '../models/Article';

@Injectable({
  providedIn: 'root',
})
export class StrapiService {
  removeCommentById(documentId: string) {
    return this.http.delete(this.apiUrl + '/comments/' + documentId);
  }
  getArticlesFiltered(keyWord: string = '', categories: any[], page: number) {
    const qs = require('qs');
    const q = qs.stringify(
      {
        pagination: {
          page: page,
          pageSize: 9,
        },
        filters: {
          $or: [
            {
              title: {
                $containsi: keyWord,
              },
            },
            {
              description: {
                $containsi: keyWord,
              },
            },
          ],
          categories: {
            name: {
              $in: categories,
            },
          },
        },
      },
      {
        encodeValuesOnly: true, // prettify URL
      }
    );
    // console.log(q);
    // console.log(q);
    return this.http.get<any>(`${this.apiUrl}/articles?${q}&populate=cover`);
  }
  getOtherCategories(documentId: string) {
    return this.http.get<any>(
      this.apiUrl + '/categories' + '?filters[documentId][$ne]=' + documentId
    );
  }
  getTestimonials() {
    return this.http.get<any>(this.apiUrl + '/testimonials?populate=user');
  }
  private apiUrl = environment.STRAPI_URL + '/api';
  // private headers = new HttpHeaders({
  //   'Content-Type': 'application/json',
  //   Authorization:
  //     'f94f27b5f596ce309a83e9c75a54920e7cb2dcf3388c53fe57590f014df70d59d6039eff1bb327ba33fdec2b9c8459020e663d2699b3345dbd52f047c14ae73ae5c97bbce006864b8dd12a5da60c6cf9a7cc7269131b7531083756e28f3bee5dea1a94523569a943f505833cffb8c896f05b2a23ba6e9ba6603913dacaa70b7e',
  // });
  constructor(private http: HttpClient) {}
  getContentBanners(): Observable<any> {
    return this.http.get<any>(this.apiUrl + '/home-banners?populate=cover');
  }
  getCategoryById(documentId: string): Observable<any> {
    return this.http.get<any>(
      this.apiUrl + '/categories/' + documentId + '?populate=cover'
    );
  }
  getArticles(): Observable<any> {
    return this.http.get<any>(this.apiUrl + '/articles?populate=cover');
  }

  getArticle(documentId: string): Observable<any> {
    return this.http.get<any>(
      ` ${this.apiUrl}/articles/${documentId}?populate=cover&populate=images`
    );
  }

  getCategories(): Observable<any> {
    return this.http.get<any>(this.apiUrl + '/categories?populate=cover');
  }

  saveComment(data: any): Observable<any> {
    return this.http.post<any>(
      this.apiUrl + '/comments',
      data /* {
      headers: this.headers,
    }*/
    );
  }
  getCommentsByIdArticle(
    idArticle: string,
    page: number,
    pageSize: number
  ): Observable<any> {
    // const headers = new HttpHeaders({
    //   'Content-Type': 'application/json',
    //   Authorization:
    //     '5ecb866399ba8a2764f5b90bbfa513045a977a8f2e4a77c7c5cd0a9e6f3150b3bafe89ddc614dbe778fa22315a7c415c2ef6ad18718589178ce0d05a324869baa068a99bcab5b55b2b8298aff23580476f3217f4cb28013dff88b022f68740b65160ac360b7b3d57efaed80da79bc92b45160c4d3f62ad865ad4c52f9e6a869d',
    // });
    // console.log(this.apiUrl);
    return this.http.get<any>(
      `${this.apiUrl}/comments?filters[article][documentId][$eq]=${idArticle}&sort=publishedAt:desc&populate=user&pagination[page]=${page}&pagination[pageSize]=${pageSize}`
      // { headers }
    );
  }
  uploadImage(file: File): Observable<any> {
    const formData = new FormData();
    formData.append('files', file);
    return this.http.post(this.apiUrl + '/upload', formData);
  }
  addImageToProfile(id: string) {}

  getOtherPopularArticles(idCurrentAricle): Observable<any> {
    return this.http.get(
      this.apiUrl +
        `/articles?populate=comments&filters[documentId][$ne]=${idCurrentAricle}`
    );
  }

  getArticlesByCategory(idCategory: string): Observable<any> {
    // console.log(idCategory + '+++++++++');
    return this.http.get(
      this.apiUrl +
        `/articles?filters[categories][documentId][$eq]=${idCategory}&populate=cover`
    );
  }
}
