import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { StrapiService } from './strapi.service';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  private baseUrl = environment.STRAPI_URL + '/api/auth';
  private userSubject = new BehaviorSubject<any | null>(null);
  public user$: Observable<any | null> = this.userSubject.asObservable();
  constructor(
    private http: HttpClient,
    private strapiService: StrapiService,
    @Inject(PLATFORM_ID) private plateformID: any
  ) {
    // localStorage.removeItem('user');
    if (isPlatformBrowser(this.plateformID)) {
      const user = localStorage.getItem('user');
      if (user) {
        this.userSubject.next(JSON.parse(user));
      }
    }
  }

  // Récupérer l'utilisateur connecté
  get user(): Observable<any | null> {
    return this.userSubject.asObservable();
  }

  // Inscription
  register(email: string, password: string, username: string): Observable<any> {
    return this.http
      .post(`${this.baseUrl}/local/register`, {
        email,
        password,
        username,
      })
      .pipe(
        tap((response: any) => {
          localStorage.setItem('jwt', response.jwt);
          localStorage.setItem('user', JSON.stringify(response.user));
          this.userSubject.next(response.user);
        })
      );

    /*.subscribe({
        next: (userResInscr: any) => {
          // console.log(userResInscr);
          this.strapiService.uploadImage(formData.file).subscribe({
            next: (resImage) => {
              this.setUserImage(
                resImage[0].id,
                resImage[0].formats?.thumbnail?.url,
                userResInscr.user.id
              ).subscribe({
                next: (finalUserRes) => {
                  localStorage.setItem('jwt', userResInscr.jwt);
                  // console.log(finalUserRes);
                  // console.log('------');
                  localStorage.setItem('user', JSON.stringify(finalUserRes));
                  console.log(localStorage.getItem('user'));
                  this.userSubject.next(finalUserRes);
                  resultat.jwt = userResInscr.jwt;
                  resultat.user = finalUserRes;
                },
                error: (errSettingImg) => {
                  resultat.error = errSettingImg.error.error.message;
                },
              });
            },
            error: (errorUplodingImg) => {
              resultat.error = errorUplodingImg.error.error.message;
            },
          });
        },
        error: (errRegisringUser) => {
          resultat.error = errRegisringUser.error.error.message;
        },
      })*/
  }

  // Connexion
  login(identifier: string, password: string): Observable<any> {
    return this.http
      .post(`${this.baseUrl}/local/`, {
        identifier,
        password,
      })
      .pipe(
        tap((response: any) => {
          localStorage.setItem('jwt', response.jwt);
          localStorage.setItem('user', JSON.stringify(response.user));
          this.userSubject.next(response.user);
        })
      );
  }

  // Déconnexion
  logout(): void {
    localStorage.removeItem('jwt');
    localStorage.removeItem('user');
    this.userSubject.next(null);
  }

  // Récupérer le token
  getToken(): string | null {
    return localStorage.getItem('jwt');
  }
  setUserSubject(user: any) {
    this.userSubject.next(user);
  }
  setUserImage(
    imgId: string,
    imglink: string,
    userId: string
  ): Observable<any> {
    return this.http.put(
      this.baseUrl.replaceAll('/auth', '') + '/users/' + userId,
      {
        imglink: imglink,
        imgid: imgId,
      }
    );
  }
}
